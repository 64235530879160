define(['$window', 'app', 'siteObj'], function($window, app, siteObj) {
  

  var galleryDetails = function() {
    var obj = {};

    obj.config = {
      alertTimeout: 5000,
      scrollDuration: 350,
      scrollTimeout: 50,
      historyLength: 5,
      velThreshold: 1,
      offsetTop: 50
    };

    obj.select = {
      nav: '.galleryDetails_nav',
      bullet: '.galleryDetails_navBullet',
      up: '.galleryDetails_navUp',
      down: '.galleryDetails_navDown',
      pages: '.galleryDetails_page',
      images: '.galleryDetails_image',
      slider: '.galleryDetails_slider',
      container: '.galleryDetails_container',
      selection: '.galleryDetails_navSelection',
      alert: '.galleryDetails_alert',
      prop: '[data-property-key]',
      next: '.galleryDetails_next'
    };

    obj.attr = {
      title: 'data-title',
      page: 'data-page',
      base: 'data-base',
      inactive: 'data-inactive',
      propkey: 'data-property-key',
      propval: 'data-property-value',
      show: 'data-show',
      hide: 'data-hide',
      hideNav: 'data-ui-hide-nav'
    };

    obj.init = function(el) {
      obj.current = 0;
      obj.element = el;
      obj.links = [];
      obj.nav = el.querySelector(obj.select.nav);
      obj.downButton = el.querySelector(obj.select.down);
      obj.upButton = el.querySelector(obj.select.up);
      obj.pages = el.querySelectorAll(obj.select.pages);
      obj.images = el.querySelectorAll(obj.select.images);
      obj.slider = el.querySelector(obj.select.slider);
      obj.container = el.querySelector(obj.select.container);
      obj.selection = el.querySelector(obj.select.selection);
      obj.alert = el.querySelector(obj.select.alert);
      obj.top = el.getBoundingClientRect().top + $window.pageYOffset;
      $window.document.title = el.getAttribute(obj.attr.title);

      obj.buildNav();
      obj.addListeners();
      obj.navigate(0);
      obj.resize();
      obj.moveImages();
      obj.getProps();

      _throttle('resize', 'optimizedResize');
      app.subscribe('galleryDetails/alert', obj.showAlert);
      app.subscribe('galleryDetails/lock', obj.lock);
      app.subscribe('galleryDetails/unlock', obj.unlock);

      return obj;
    };

    obj.buildNav = function() {
      var bullet;

      while ((bullet = obj.nav.querySelector(obj.select.bullet))) {
        obj.nav.removeChild(bullet);
      }

      for (var index = 0; index < obj.pages.length; index++) {
        bullet = document.createElement('div');
        bullet.className = obj.select.bullet.substr(1);
        bullet.setAttribute(obj.attr.page, index);
        bullet.addEventListener('click', obj.navigate.bind(null, index), false);

        obj.nav.insertBefore(bullet, obj.downButton);
        obj.links.push(bullet);
      }
    };

    var _throttle = function(type, name, obj) {
      obj = obj || $window;
      var running = false;
      var func = function() {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function() {
          obj.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      obj.addEventListener(type, func);
    };

    obj.resize = function() {
      var top = obj.top;

      if (siteObj.siteIsMobile) {
        top += obj.config.offsetTop;
      }

      obj.element.style.height = 'calc(100vh - ' + top + 'px)';
    };

    obj.moveImages = function() {
      var base = obj.container.offsetWidth / 2;

      for (var index = 0; index < obj.images.length; index++) {
        obj.move(obj.images[index], base);
      }
    };

    obj.move = function(el, base) {
      if (el.offsetWidth <= obj.container.offsetWidth) {
        el.parentElement.style.transform = 'translateX(-50%)';
        return;
      }

      var imageBase = parseInt(el.getAttribute(obj.attr.base)) / 100 * el.offsetWidth;
      var delta = Math.max(imageBase, obj.container.offsetWidth - base);
      delta = Math.min(delta, el.offsetWidth - base);
      delta = Math.floor(delta);

      el.parentElement.style.transform = 'translateX(' + -delta + 'px)';
    };

    obj.addListeners = function() {
      $window.addEventListener('optimizedResize', obj.moveImages, false);
      obj.container.addEventListener('wheel', obj.wheel, false);
      obj.container.addEventListener('touchstart', obj.touchstart, false);
      obj.container.addEventListener('touchmove', obj.touchmove, false);
      obj.container.addEventListener('touchend', obj.touchend, false);
      obj.upButton.addEventListener('click', obj.previous, false);
      obj.downButton.addEventListener('click', obj.next, false);

      var nextBtns = obj.element.querySelectorAll(obj.select.next);

      for (var index = 0; index < nextBtns.length; index++) {
        nextBtns[index].addEventListener('click', obj.next, false);
      }
    };

    obj.getProps = function() {
      var props = obj.element.querySelectorAll(obj.select.prop);
      obj.props = {};

      for (var index = 0; index < props.length; index++) {
        var key = props[index].getAttribute(obj.attr.propkey);
        var value = props[index].getAttribute(obj.attr.propval);

        if (!key || value === null) {
          continue;
        }

        obj.props[key] = value;
      }
    };

    obj.showAlert = function(prop) {
      if (obj.alertTimeout) {
        $window.clearTimeout(obj.alertTimeout);
        obj.alertTimeout = null;
      }

      obj.alert.innerText = obj.props[prop] || obj.props.defaultAlert;
      obj.alert.setAttribute(obj.attr.show, 'true');
      obj.alertTimeout = $window.setTimeout(obj.resetAlert, obj.config.alertTimeout);
    };

    obj.resetAlert = function() {
      obj.alertTimeout = null;
      obj.alert.setAttribute(obj.attr.show, 'false');
    };

    obj.navigate = function(page) {
      if (!obj.pages[page]) {
        return;
      }

      var offset = -100 * page;

      obj.slider.style.transform = 'translateY(' + offset + '%)';
      obj.selection.style.top = obj.links[page].offsetTop + 'px';

      if (obj.current === 0) {
        obj.upButton.setAttribute(obj.attr.inactive, 'false');
      } else if (obj.current === obj.pages.length - 1) {
        obj.downButton.setAttribute(obj.attr.inactive, 'false');
      }

      obj.current = page;

      var hideNav = obj.pages[page].getAttribute(obj.attr.hideNav);
      obj.nav.setAttribute(obj.attr.hide, hideNav);

      if (obj.current === 0) {
        obj.upButton.setAttribute(obj.attr.inactive, 'true');
      } else if (obj.current === obj.pages.length - 1) {
        obj.downButton.setAttribute(obj.attr.inactive, 'true');
      }

      app.publish('tracking/record', 'GalleryDetails', 'viewed', 'image viewed', page);
    };

    obj.previous = function() {
      if (obj.current === 0) {
        return;
      }

      obj.navigate(obj.current - 1);
    };

    obj.next = function() {
      if (obj.current === obj.pages.length - 1) {
        return;
      }

      obj.navigate(obj.current + 1);
    };

    var _lock = false;

    var _scroll = {
      timeStamp: 0,
      lastTime: 0,
      lock: false,
      last: {
        deltaY: 0
      }
    };

    obj.wheel = function(ev) {
      if (_lock || $window.pageYOffset > 0) {
        return;
      }

      if (obj.current !== obj.pages.length - 1) {
        ev.preventDefault();
      }

      if (ev.timeStamp - _scroll.lastTime >= obj.config.scrollTimeout) {
        _scroll.lock = false;
      } else {
        _scroll.lock = Math.abs(ev.deltaY) <= Math.abs(_scroll.last.deltaY);
      }

      _scroll.lastTime = ev.timeStamp;
      _scroll.last = ev;

      if (ev.timeStamp - _scroll.timeStamp < obj.config.scrollDuration) {
        return;
      }

      if (!_scroll.lock) {
        if (ev.deltaY < 0) {
          obj.previous();
        } else if (ev.deltaY > 0) {
          obj.next();
        }

        _scroll.timeStamp = ev.timeStamp;
        _scroll.lock = true;
      }
    };

    var _touch = {
      history: [],
      scroll: false
    };

    obj.touchstart = function(ev) {
      if (_lock) {
        return;
      }

      _touch.scroll = ($window.pageYOffset !== 0);
      _touch.history = [{
        y: ev.changedTouches[0].pageY,
        t: ev.timeStamp
      }];
    };

    obj.touchmove = function(ev) {
      if (_lock || _touch.scroll) {
        return;
      }

      _touch.history.push({
        y: ev.changedTouches[0].pageY,
        t: ev.timeStamp
      });

      if (_touch.history.length > obj.config.historyLength) {
        _touch.history.shift();
      }

      var index = _touch.history.length - 1;
      var deltaY = _touch.history[index].y - _touch.history[index - 1].y;

      _touch.scroll = (deltaY > 0 && obj.current === 0) ||
        (deltaY < 0 && obj.current === obj.pages.length - 1);

      // safari fix
      if (!_touch.scroll) {
        ev.preventDefault();
      }
    };

    obj.touchend = function() {
      if (_lock || _touch.scroll) {
        return;
      }

      // Last touch is unreliable, don't push to history
      var velY = 0;

      for (var index = 1; index < _touch.history.length; index++) {
        var deltaY = _touch.history[index].y - _touch.history[index - 1].y;
        var deltaT = _touch.history[index].t - _touch.history[index - 1].t;

        velY += deltaY / deltaT;
      }

      if (Math.abs(velY) < obj.config.velThreshold) {
        return;
      }

      if (velY > 0) {
        obj.previous();
      } else {
        obj.next();
      }
    };

    obj.lock = function() {
      _lock = true;
    };

    obj.unlock = function() {
      _lock = false;
    };

    obj.vars = {
      touch: _touch,
      scroll: _scroll,
      lock: _lock
    };

    return obj;
  };

  return galleryDetails;
});
